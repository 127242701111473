<template>
  <v-row class="customer-detail custom-border-to">
    <v-col md="12" sm="12" class="pt-0">
      <div class="new-white-box-overview-main nav">
        <div class="col-md-12 py-0 customer-image">
          <Status :status="customerActivated"></Status>
          <!-- <span>
            <v-chip
              color="blue white--text"
              label
              v-if="company.company_type == 'company'"
            >
              <span class="font-size-16"> Company </span>
            </v-chip>
            <v-chip
              color="red white--text"
              label
              v-if="company.company_type == 'personal'"
            >
              <span class="font-size-16"> Individual </span>
            </v-chip>
          </span> -->
          <div class="py-4">
            <v-skeleton-loader
              class="custom-skeleton"
              v-if="pageLoading"
              type="image"
            >
            </v-skeleton-loader>
            <template v-else>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage ? getProfileImage : $defaultProfileImage"
                aspect-ratio="1"
                max-height="140"
                max-width="140"
                class="margin-auto custom-grey-border custom-border-radius-50"
                transition="fade-transition"
              >
              </v-img>
            </template>
          </div>
        </div>
        <div class="col-md-12 pt-0 px-0">
          <v-skeleton-loader
            class="custom-skeleton"
            v-if="pageLoading"
            type="text@5"
          >
          </v-skeleton-loader>

          <table v-else class="width-100">
            <template v-if="true">
              <tr v-if="false">
                <td class="pb-2 font-size-18 text-capitalize">Security Code</td>
                <td class="pb-2 font-size-18 text-capitalize">
                  {{ company.security_code }}
                </td>
              </tr>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-16 color-custom-blue py-1"
                >
                  Company Information
                </th>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize" width="120">
                  Company #
                </td>
                <td class="py-1">
                  <div class="font-weight-600 font-size-18">
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="button"
                    >
                    </v-skeleton-loader>
                    <Barcode v-else :barcode="getBarcode"></Barcode>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize" valign="top">
                  Company Name
                </td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.company_name">{{
                    company.company_name
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No company name</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize" valign="top">
                  Display Name
                </td>
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="company && company.display_name"
                >
                  {{ company.display_name }}
                </td>
                <em v-else class="text-muted ml-2"> no display name</em>
              </tr>
              <tr>
                <td class="py-1 font-size-18">Email</td>
                <td class="py-1 font-size-18">
                  <template v-if="company.company_email">{{
                    company.company_email
                  }}</template>
                  <template v-else
                    ><em class="text-muted"> no email</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Phone No.</td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.company_number">{{
                    company.company_number
                  }}</template>
                  <template v-else
                    ><em class="text-muted">no phone number</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">fax</td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.company_fax">{{
                    company.company_fax
                  }}</template>
                  <template v-else
                    ><em class="text-muted">No company fax</em></template
                  >
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Category</td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.category">
                    <v-chip
                      :color="company?.category_color"
                      small
                      label
                      outlined
                      >{{ company.category }}</v-chip
                    >
                  </template>
                  <template v-else
                    ><em class="text-muted">No category</em></template
                  >
                </td>
              </tr>
              <tr v-if="company?.tags?.length">
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-19 color-custom-blue py-1"
                >
                  Tags
                </th>
              </tr>
              <tr v-if="company.tags && company.tags.length > 0">
                <td class="font-size-18 py-1" colspan="2">
                  <template v-if="company.tags && company.tags.length > 0">
                    <v-chip
                      small
                      v-for="(row, index) in company.tags"
                      :key="index"
                      class="mr-2"
                      text-color="white"
                      :color="row.color"
                      >{{ row.text }}</v-chip
                    >
                  </template>
                  <em v-else class="text-muted"> no tags</em>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-19 color-custom-blue py-1"
                >
                  Contact Information
                </th>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize" width="120">
                  Company #
                </td>
                <td class="py-1">
                  <div
                    class="font-weight-600 font-size-18"
                    v-if="company.company_type == 'personal'"
                  >
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="button"
                    >
                    </v-skeleton-loader>
                    <Barcode v-else :barcode="getBarcode"></Barcode>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Full Name</td>
                <td class="py-1 font-size-18 text-capitalize">
                  {{ company.default_person?.full_name }}
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Display Name</td>
                <!--   <pre>{{ company }}</pre> -->
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="
                    company &&
                    company.default_person &&
                    company.default_person.display_name
                  "
                >
                  {{ company.default_person.display_name }}
                </td>
                <em v-else class="text-muted ml-2"> no display name</em>
              </tr>
              <tr>
                <td class="py-1 font-size-18">Email Address</td>
                <td
                  class="py-1 font-size-18"
                  v-if="
                    company &&
                    company.default_person &&
                    company.default_person.primary_email
                  "
                >
                  {{ company.default_person.primary_email }}
                </td>
                <td v-else><em class="text-muted"> no email address</em></td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Mobile Number</td>
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="
                    company &&
                    company.default_person &&
                    company.default_person.primary_phone
                  "
                >
                  {{ company.default_person.primary_phone }}
                </td>
                <td v-else class="pb-2">
                  <em class="text-muted"> no mobile number</em>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">
                  Landline Number
                </td>
                <td
                  class="py-1 font-size-18 text-capitalize"
                  v-if="
                    company &&
                    company.default_person &&
                    company.default_person.did
                  "
                >
                  {{ company.default_person.did }}
                </td>
                <td v-else class="py-1">
                  <em class="text-muted"> no Landline Number</em>
                </td>
              </tr>
              <tr>
                <td class="py-1 font-size-18 text-capitalize">Category</td>
                <td class="py-1 font-size-18 text-capitalize">
                  <template v-if="company.category">
                    <v-chip
                      :color="company?.category_color"
                      small
                      label
                      outlined
                      >{{ company.category }}</v-chip
                    >
                  </template>
                  <template v-else
                    ><em class="text-muted">No category</em></template
                  >
                </td>
              </tr>
              <tr v-if="company?.tags?.length">
                <th
                  colspan="2"
                  class="grey lighten-4 font-size-19 color-custom-blue py-1"
                >
                  Tags
                </th>
              </tr>
              <tr v-if="company.tags && company.tags.length > 0">
                <td class="font-size-18 py-1" colspan="2">
                  <template v-if="company.tags && company.tags.length > 0">
                    <v-chip
                      small
                      v-for="(row, index) in company.tags"
                      :key="index"
                      class="mr-2"
                      text-color="white"
                      :color="row.color"
                      >{{ row.text }}</v-chip
                    >
                  </template>
                  <em v-else class="text-muted"> no tags</em>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import Status from "@/view/pages/partials/Status.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";

export default {
  name: "contact-details",
  title: "Company",
  mixins: [CommonMixin, FileManagerMixin],
  data() {
    return {
      imageLoading: false,
      pageLoading: true,
    };
  },
  props: {
    company: {
      type: Object,
      /* required: true, */
      default: function () {
        return {};
      },
    },
    // pageLoading: {
    //   type: Boolean,
    //   default: true,
    // },
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      let customerId = _this.company && _this.company.id ? _this.company.id : 0;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param, customerId, "company")
        .then((response) => {
          _this.company.profile_logo = _this.lodash.head(response);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getDetailType(param) {
      if (param === 1) {
        return "Email";
      }
      return "Phone";
    },
  },
  components: {
    Status,
    Barcode,
  },
  computed: {
    customerActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.company) === false) {
        return _this.company.activated;
      }
      return false;
    },
    fullname() {
      let name = "";
      const _this = this;
      if (
        _this.company &&
        _this.lodash.isEmpty(_this.company.persons) === false
      ) {
        for (let i = 0; i < _this.company.persons.length; i++) {
          if (_this.company.persons[i].default) {
            name =
              _this.lodash.startCase(_this.company.persons[i].title) +
              ". " +
              _this.company.persons[i].first_name +
              " " +
              _this.company.persons[i].last_name;
          }
        }
      }
      return name;
    },
    primaryEmail() {
      let email = "";
      const _this = this;
      if (
        _this.company &&
        _this.lodash.isEmpty(_this.company.details) === false
      ) {
        for (let i = 0; i < _this.company.details.length; i++) {
          if (_this.company.details[i].type === 1) {
            email = _this.company.details[i].value;
            break;
          }
        }
      }
      return email;
    },
    primaryPhone() {
      let phone = "";
      const _this = this;
      if (
        _this.company &&
        _this.lodash.isEmpty(_this.company.details) === false
      ) {
        for (let i = 0; i < _this.company.details.length; i++) {
          if (_this.company.details[i].type === 2) {
            phone = _this.company.details[i].value;
            break;
          }
        }
      }
      return phone;
    },
    getProfileImage() {
      if (!this.company.profile_logo) {
        return null;
      }
      return this.company.profile_logo.file.url;
    },
    getBarcode() {
      if (!this.company.barcode) {
        return "";
      }
      return this.company.barcode;
    },
  },
};
</script>
